
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getPostList } from '@/api/post';
import BoardBasicList from '@/components/board/basic/list.vue';
import BoardDefaultList from '@/components/board/default/list.vue';
import BoardPhotoList from '@/components/board/interview/list.vue';
import BoardSupportList from '@/components/board/support/list.vue';
import BoardEventList from '@/components/board/event/list.vue';
import BoardMentoList from '@/components/board/mento/list.vue';
import BoardYoutubeList from '@/components/board/youtube/list.vue';
import BoardGuideList from '@/components/board/guide/list.vue';
import BoardSearch from '@/components/board/search.vue';
import BoardPagination from '@/components/board/pagination.vue';
import { getMenu } from '@/api/menu';
import { IBoard, IMenuDetail, IPost } from '@/types';
import { UserModule } from '@/store/user';
import { getBoard } from '@/api/board';

@Component({
  name: 'BoardIndex',
  components: {
    BoardSearch,
    BoardBasicList,
    BoardDefaultList,
    BoardPhotoList,
    BoardSupportList,
    BoardEventList,
    BoardMentoList,
    BoardGuideList,
    BoardYoutubeList,
    BoardPagination,
  },
})
export default class extends Vue {
  @Watch('$route')
  private handleChangeMenu() {
    /* this.init(); */
  }

  mounted() {
    this.init();
  }

  private menu: IMenuDetail | null = null;

  private board: IBoard | null = null;

  private postList: IPost[] = [];

  private listTotal = 0;

  private totalPages = 0;

  // private container: boolean;

  private postListQuery = {
    page: 0,
    size: 12,
    searchType: '',
    searchValue: '',
    boardUid: '',
    categoryList: [],
  };

  private init() {
    getMenu(this.$route.params.menuUid).then((res: any) => {
      this.menu = res.data;
      getBoard(res.data.board.uid).then((response) => {
        this.board = response.data;
        this.postListQuery = {
          page: 0,
          size: response.data.listSize,
          searchType: 'all',
          searchValue: '',
          boardUid: response.data.uid,
          categoryList: [],
        };
        if (this.$route.query) {
          this.postListQuery = {
            ...this.postListQuery,
            ...this.$route.query,
            size: response.data.listSize,
            boardUid: response.data.uid,
          };
        }
        // console.log(this.board?.boardSkin);
        this.getPostList();
      });
    }).catch((error) => {
      console.error('Error fetching menu or board:', error);
      return false; // Handle promise rejection
    });
  }

  private getPostList() {
    const load = this.$loading.show();
    this.postList = [];
    getPostList(this.postListQuery).then((response: any) => {
      this.listTotal = response.data.totalElements;
      this.postList = response.data.content;
      this.totalPages = response.data.totalPages;
      load.hide();
    });
  }

  private handleSearch(search: any) {
    this.postListQuery = {
      ...this.postListQuery,
      ...search,
      page: 0,
    };
    if (search.categoryList && search.categoryList.length > 0) this.postListQuery.categoryList = search.categoryList.join(',');
    this.getPostList();
  }

  private handleChangePaging(page: number) {
    this.postListQuery.page = page;
    this.getPostList();
  }

  private handleChangeProgress(index: number) {
    this.handleSearch({ progress: index });
  }

  private handleChangeSort(orderBy: string) {
    this.handleSearch({ sort: orderBy });
  }

  private getSubContainerClass() {
    // console.log('this.board?.boardSkin');
    // console.log(this.board?.boardSkin);
    let boardSkin = '';
    if (this.board?.boardSkin) {
      boardSkin = this.board?.boardSkin;
    }
    switch (boardSkin) {
      case 'basic':
        return 'sub__container basic';
      case 'mento':
        return 'sub__container mento';
      case 'youtube':
        return 'sub__container youtube';
      case 'interview':
        return 'sub__container youtube';
      case 'default':
        return 'sub__container youtube';
      default:
        return 'sub__container';
    }
  }
  // private checkContainer(skin: string) {
  //   const container = skin;

  //   switch (container) {
  //     case 'default':
  //       return true;

  //     case 'default':
  //       return true;

  //     case 'interview':
  //       return true;

  //     case 'support':
  //       return true;

  //     case 'event':
  //       return true;

  //     case 'mento':
  //       return true;

  //     case 'youtube':
  //       return true;

  //     case 'guide':
  //       return true;

  //     default:
  //       return false;
  //   }
  // }
}
